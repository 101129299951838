import React from 'react'

export default function Badge() {
  return (
    <>
        <div class="bithu_v4owl_carousel_sect">
            <div class="bithu_v4owl_carousel_content">
                <ul>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                </ul>
                <ul>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>DEVELOPING</li>
                    <li><span><img src="assets/images/icon/mint_live_icon.svg" alt="icon" /></span>ROADMAP</li>
                </ul>
            </div>
        </div>
    </>
  )
}
