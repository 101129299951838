import './App.css';
import About from './components/about/About';
import Badge from './components/badge/Badge';
import Carousel from './components/carousel/Carousel';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import Modal from './components/modals/Modal';
import Roadmap from './components/roadmap/Roadmap';
import Team from './components/team/Team';


function App() {
  return (
    <>
    <Header />
    <Hero />
    <Badge />
    <Carousel />
    <About />
    <Roadmap />
    <Team />
    <Footer />
    <Modal />
    </>
  );
}

export default App;
