import React from 'react'

export default function Hero() {
  return (
    <>
            <div class="bithu_v1_baner_sect" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="bithu_v1_baner_left">
                            <h2>Fancy Entrance To The ProdStars Ecosystem</h2>
                            <h3><span class="count">0</span> / 10.000 Minted</h3>
                            <div class="bithu_v1_baner_buttons">
                                <button class="mint_btn hov_shape_show" data-bs-toggle="modal" data-bs-target="#mintModal">
                                    Mint now
                                    <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                    <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                    <span class="square_hov_shape_dark"></span>
                                </button>
                                <button class="widhlist_btn hov_shape_show">
                                    Whitelist Now
                                    <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                    <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                    <span class="square_hov_shape"></span>
                                </button>
                            </div>
                            <ul>
                                <li>Max 2 NFTs per wallet<br></br> Price: TBA</li>
                                <li>The team will finish the roadmap until the mint day! <br></br> <span>Mint day: TBA</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="bithu_v1_baner_right">
                            <div class="bithu_v1_baner_right_img_sect">
                                <div class="bithu_v1_baner_right_img_bg">
                                    <img src="assets/images/nft/home_img_bg.png" alt="" />
                                </div>
                                <div class="bithu_v1_baner_right_img">
                                    <img src="assets/images/nft/Character1.png" alt="avater" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
