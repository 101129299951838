import React from 'react'

export default function Footer() {
  return (
    <>
        <div class="bithu_v1_footer_sect">
            <div class="container">
                <div class="bithu_title_section text-center">
                    <h3>Join Our community & <br />get Early access </h3>
                </div>
                <div class="bithu_v1_footer_content">
                    <div class="join_comunity_btns">
                        <button class="wishlist_btn hov_shape_show uppercase">
                            Whitelist Now
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="hov_shape1"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span></a>
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="hov_shape2"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span></a>
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="square_hov_shape_dark"></span></a>
                        </button>

                        <button class="join_discord_btn hov_shape_show">
                            <img src="assets/images/icon/dis_logo.svg" alt="" />Join Discord
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span></a>
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span></a>
                            <a href="https://discord.gg/fCZYrbbs" target="_blank" rel="noreferrer"><span class="square_hov_shape_dark"></span></a>
                        </button>
                    </div>
                    <div class="footer_social_links">
                        <ul>
                            <li class="social_hov_shape_show">
                                <a href="/"><span><img src="assets/images/icon/opensea.svg" alt="" /></span></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><i class="fa-brands fa-linkedin-in"></i></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><i class="fa-brands fa-instagram"></i></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><i class="fa-brands fa-twitter"></i></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><i class="fa-brands fa-telegram"></i></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><i class="fa-brands fa-facebook"></i></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                            <li class="social_hov_shape_show">
                                <a href="/"><img src="assets/images/icon/med.svg" alt=""/></a>
                                <span class="social_hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                            </li>
                        </ul>
                    </div>
                    <div class="footer_stras_sect">
                        <div class="footer_stars">
                            <span class="star_1"><img src="assets/images/icon/star_6.svg" alt="" /></span>
                            <span class="star_2"><img src="assets/images/icon/star_3.svg" alt="" /></span>
                            <span class="star_3"><img src="assets/images/icon/star_1.svg" alt="" /></span>
                            <span class="star_4"><img src="assets/images/icon/star_7.svg" alt="" /></span>
                            <span class="star_5"><img src="assets/images/icon/star_4.svg" alt="" /></span>
                            <span class="star_6"><img src="assets/images/icon/star_2.svg" alt="" /></span>
                            <span class="star_7"><img src="assets/images/icon/star_5.svg" alt="" /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bithu_v1_main_footer">
                <div class="bithu_v1_main_footer_overlay">
                </div>
                <div class="three_charectre_img">
                    <img src="assets/images/nft/3_chr_img.png" alt="" />
                </div>
                <div class="footer_bottom">
                    <div class="footer_bottom_content">
                        <span class="footer_shapes_left"><img src="assets/images/icon/footer_shapes_left.png" alt="" /></span>
                        <span class="footer_shapes_right"><img src="assets/images/icon/footer_shapes_right.png" alt="" /></span>
                        <div class="container">
                            <div class="footer_menu">
                                <div class="bottom_footer_left">
                                    <div class="footer_logo">
                                        <a href="/"><img src="assets/images/logo.png" alt=""/></a>
                                    </div>
                                    <div class="copiright_text pl-40">
                                        Copyright © 2022 ProdStars NFT
                                    </div>
                                </div>
                                <a href="/" class="bact_to_top_btn"><img src="assets/images/icon/back_to_top.svg" alt="" /></a>
                                <div class="bottom_footer_right">
                                    <ul>
                                        <li><a href="#home">Home</a></li>
                                        <li><a href="#about">About</a></li>
                                        <li><a href="#roadmap">Roadmap</a></li>
                                        <li><a href="#team">Team</a></li>
                                        <li><a href="#faq">FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
