import React from 'react'

export default function Modal() {
  return (
    <>
    <div class="connect_modal"> 
            <div class="modal fade " id="connectModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal_overlay">
                            <div class="modal_header">
                                <h2>Connect Wallet</h2>
                                <button data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                            <div class="modal_body text-center">
                                <p>Please select a wallet to connect for start Minting your NFTs</p>
                                <div class="connect-section">
                                    <ul class="heading-list">
                                        <li><a href="/" class="connect-meta"><span><img src="assets/images/icon/MetaMask.svg" alt="Meta-mask" /></span>MetaMask</a></li>
                                        <li><a href="/"><span><img src="assets/images/icon/Formatic.svg" alt="Coinbase" /></span>Coinbase</a></li>
                                        <li><a href="/"><span><img src="assets/images/icon/Trust_Wallet.svg" alt="Trust" /></span>Trust Wallet</a></li>
                                        <li><a href="/"><span><img src="assets/images/icon/WalletConnect.svg" alt="Wallet" /></span>WalletConnect</a></li>
                                    </ul>
                                </div>
                                <h6>By connecting your wallet, you agree to our <a href="/">Terms of Service</a> and our <a href="/">Privacy Policy</a>.</h6>
                            </div>
                            <div class="modal_bottom_shape">
                                <span class="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                <span class="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="connect_modal"> 
            <div class="modal fade " id="download-metamask" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal_overlay">
                            <div class="modal_header">
                                <h2>Connect Wallet</h2>
                                <button data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                            <div class="modal_body text-center">
                                <p>Please download & install metamask extension!</p>
                                <div class="connect-section">
                                    <ul class="heading-list">
                                        <li><a href="https://metamask.io/download/" target="_blank" rel="noreferrer"><span><img src="assets/images/icon/MetaMask.svg" alt="Meta-mask" /></span>MetaMask</a></li>
                                    </ul>
                                </div>
                                <h6>By connecting your wallet, you agree to our <a href="/">Terms of Service</a> and our <a href="/">Privacy Policy</a>.</h6>
                            </div>
                            <div class="modal_bottom_shape">
                                <span class="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                <span class="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mint_modal"> 
            <div class="modal fade " id="mintModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal_overlay2">
                            <div class="modal_header">
                                <h2>Collect YOUR NFT before end</h2>
                                <button data-bs-dismiss="modal" aria-label="Close">
                                    <span class="clossbtn_bg"><i class="fa-solid fa-xmark"></i></span>
                                </button>
                            </div>
                            <div class="modal_body text-center">
                                <div class="mint_img">
                                    <img src="assets/images/icon/mint-img.png" alt="img" />
                                </div>
                                <div class="mint_count_list">
                                    <ul>
                                        <li>
                                            <h5>Remaining</h5>
                                            <h5>4583/<span>9999</span></h5>
                                        </li>
                                        <li>
                                            <h5>Price</h5>
                                            <h5>0.15 ETH</h5>
                                        </li>
                                        <li>
                                            <h5>Quantity</h5>
                                            <div class="mint_quantity_sect">
                                                <button onclick="buttonClick_Dec();">-</button>
                                                <input type="text" id="quantity" value="2" />
                                                <button onclick="buttonClick_Inc();">+</button>
                                            </div>
                                            <h5><span>0.30</span> ETH</h5>
                                        </li>

                                    </ul>
                                </div>
                                <button class="modal_mint_btn hov_shape_show">
                                    MINT NOW
                                    <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                    <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L_dark.svg" alt="" /></span>
                                </button>
                                <h6>Presale & Whitelist : Soldout</h6>
                            </div>
                            <div class="modal_bottom_shape">
                                <span class="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                <span class="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
