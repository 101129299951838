import React from 'react'

export default function Carousel() {
  return (
    <>
        <div class="bithu_v2_owl_carousel_sect">
            <div class="bithu_v2_owl_carousel_content bithu_v2_owl_carousel_content_row1">
                <ul>
                    <li><img src="assets/images/nft/carousel_img_1.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_2.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_3.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_4.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_5.png" alt="" /></li>
                    <li class="bg_none"></li>
                </ul>
                <ul>
                    <li><img src="assets/images/nft/carousel_img_1.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_2.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_3.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_4.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_5.png" alt="" /></li>
                    <li class="bg_none"></li>
                </ul>
            </div>
            <div class="bithu_v2_owl_carousel_content bithu_v2_owl_carousel_content_row2">
                <ul>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_6.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_7.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_8.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_9.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_10.png" alt="" /></li>
                </ul>
                <ul>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_6.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_7.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_8.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_9.png" alt="" /></li>
                    <li class="bg_none"></li>
                    <li><img src="assets/images/nft/carousel_img_10.png" alt="" /></li>
                </ul>
            </div>
        </div>
    </>
  )
}
