import React from 'react'

export default function About() {
  return (
    <>
        <div class="v2_about_us_section" id="about">
            <div class="v2_about_overlay"></div>
            <div class="container">
                <div class="bithu_title_section text-center">
                    <h2><span class="shape_left"><img src="assets/images/icon/title_shapes2.svg" alt="" /></span>The Story<span><img src="assets/images/icon/title_shapes.svg" alt=""/></span></h2>
                    <h3>About Us</h3>
                </div>
                <div class="v2_about_us_content">
                    <div class="v2_about_us_text">
                        <p>The ProdStars is more than just a cute star-like profile picture project. Our mission is to create a decentralized ecosystem for the music industry, provide as much utility as possible to our ProdStars NFT holders and smaller producers, beat makers across the broader NFT space, and help foster Web3 culture into the mainstream.</p>
                        <br></br>
                        <p>One of the ProdStars's core values is to support producers, beat makers, and rap music artists with their entrance into the broader Web3 ecosystem. The maker and developer behind this idea -Omer Aydin- is the owner of this vision. He instantly found love in his journey within the Web3 world after spending time in communities such as Ninja Squad NFT and Gobz NFT. He very quickly became a tremendous community member and began providing valuable content on his Twitter account @nftlernet.</p>
                        <br></br>
                        <p>Besides his love of web3, he has made a career change from a successful lawyer to a blockchain developer, and now he decided to create his own long-life ecosystem.</p>
                        <br></br>
                        <p>His childhood love was creating rap songs such as "Coin Ver".</p>
                        <br></br>
                        <p>He gathered all these passions in one great ecosystem. Now he creates an IP-protected, decentralized beat marketplace for music producers, beat makers, and hip hop artists.</p>
                        <br></br>
                        <p>His journey is not an "alone wolf" style journey. He is leveraging his best supporters Mert Kamali, Ahu Yetkin, Q the Geek, Barni Barnas, Gurkan Kaya, Ekrem Ay, Baran Ozgur.</p>
                        <ul>
                            <li>
                                <h4>To be minted</h4>
                                <h6><span class="counter">10.000 ProdStars NFT</span></h6>
                            </li>
                            <li>
                                <h4>Utility</h4>
                                <h6><span class="counter">Ecosystem for rap music makers</span></h6>
                            </li>
                        </ul>
                    </div>
                    <div class="v2_about_left_img">
                        <span><img src="assets/images/nft/about_image_left.png" alt="" /></span>
                    </div>
                    <div class="v2_about_right_img">
                        <span><img src="assets/images/nft/about_image_right.png" alt="" /></span>
                    </div>
                </div>
                <div class="about_us_text_card_sect">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="about_us_text_card">
                                <h3><img src="assets/images/icon/aboutus_card_icon1.png" alt="" />Beat Marketplace</h3>
                                <p>We’ll create an extensive web3 ecosystem for rap music producers and artists. Producers will be able to sell their beats as an NFT at our marketplace via our $PROD</p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="about_us_text_card">
                                <h3><img src="assets/images/icon/aboutus_card_icon2.png" alt="" />$PROD</h3>
                                <p>$PROD is an ERC20 utility token for the ProdStars Ecosystem. It will serve as the currency for the medium of exchange for our gasless NFT beat marketplace. </p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="about_us_text_card">
                                <h3><img src="assets/images/icon/aboutus_card_icon3.png" alt="" />Staking</h3>
                                <p>Your ProdStar NFT will produce $PROD each day, but not simply staked, they are sent on Studio Tour! Every day they bring loot and rewards back. In the future.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
