import React from 'react'

export default function Team() {
  return (
    <>
        <div class="bithu_team_sect v1_team_sect v3_team_sect" id="team">
           <div class="v1_team_overlay team-section">
               <div class="container">
                   <div class="bithu_title_section text-center">
                       <h2 class="uppercase"><span class="shape_left"><img src="assets/images/icon/title_shapes2.svg" alt="" /></span>Team Members<span>
                        <img src="assets/images/icon/title_shapes.svg" alt="" /></span></h2>
                       <h3>Meet The Crew</h3>
                   </div>
                   <div class="bithu_team_content">
                       <div class="row">
                           <div class="col-lg-3 col-md-4 col-sm-6">
                               <div class="bithu_team_card hov_shape_team">
                                   <div class="team_member_img">
                                       <img src="assets/images/team/omer-bw.png" alt="" />
                                   </div>
                                   <h3>Omer Aydin</h3>
                                   <h4>Blockchain Developer & Founder</h4>
                                   <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <div class="team_social_icon">
                                       <ul>
                                           <li><a href="https://www.instagram.com/attorney.omer/"><i class="fa-brands fa-instagram"></i></a></li>
                                           <li><a href="https://twitter.com/nftlernet"><i class="fa-brands fa-twitter"></i></a></li>
                                           <li><a href="https://www.linkedin.com/in/avomeraydin/"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                       </ul>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6">
                               <div class="bithu_team_card hov_shape_team">
                                   <div class="team_member_img">
                                       <img src="assets/images/team/mert-bw.png" alt="" />
                                   </div>
                                   <h3>Mert Kamali</h3>
                                   <h4>Web Developer & Blockchain Developer</h4>
                                   <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <div class="team_social_icon">
                                       <ul>
                                           <li><a href="https://www.instagram.com/mert.kama17/"><i class="fa-brands fa-instagram"></i></a></li>
                                           <li><a href="https://www.linkedin.com/in/ibrahim-mert-kamali/"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                       </ul>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6">
                               <div class="bithu_team_card hov_shape_team">
                                   <div class="team_member_img">
                                       <img src="assets/images/team/ahu-bw.png" alt="" />
                                   </div>
                                   <h3>Ahu Yetkin</h3>
                                   <h4>Aritst</h4>
                                   <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <div class="team_social_icon">
                                       <ul>
                                           <li><a href="https://www.instagram.com/ahuyetkin/"><i class="fa-brands fa-instagram"></i></a></li>
                                       </ul>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6">
                               <div class="bithu_team_card hov_shape_team">
                                   <div class="team_member_img">
                                       <img src="assets/images/team/kubi-bw.png" alt="" />
                                   </div>
                                   <h3>Q the Geek</h3>
                                   <h4>Community Manager</h4>
                                   <span class="hov_shape1"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <span class="hov_shape2"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                   <div class="team_social_icon">
                                       <ul>
                                           <li><a href="https://twitter.com/QtheGeek"><i class="fa-brands fa-twitter"></i></a></li>
                                           <li><a href="https://www.instagram.com/qthegeek/"><i class="fa-brands fa-instagram"></i></a></li>
                                       </ul>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </>
  )
}
