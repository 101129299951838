import React from 'react'

export default function Roadmap() {
  return (
    <>
        <div class="v2_roadmap_sect" id="roadmap">
            <div class="container">
                <div class="bithu_title_section text-center">
                    <h2><span class="shape_left"><img src="assets/images/icon/title_shapes2.svg" alt="" /></span>Our Goals<span><img src="assets/images/icon/title_shapes.svg" alt="" /></span></h2>
                    <h3>Roadmap-First Approach</h3>
                    <h6>Skip all the bullshit at the NFT ecosystem. We’ll build first, and publish everything after that. You don’t need to worry about the roadmap and development stage. Our NFT sale is for feeding to broaden the application architecture.</h6>
                </div>
                <div class="v2_rodmap_card_sect">
                    <div class="v2_roadmap_divider">
                        <img src="assets/images/nft/roadmap_v2_middle_shape.png" alt="" />
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_odd">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Development and Testnet Deployment <small>Now</small> <span><img src="assets/images/icon/roadmap_v2_check.svg" alt="" /></span></h3>
                                    <p>Our team is currently working on developing cool and useful stuff and testing them on the testnet.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_even v2_roadmap_card_sect_2">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Publishing <small>Q4</small> <span><img src="assets/images/icon/roadmap_spiner.png" alt="" /></span></h3>
                                    <p>At this stage, we will finish all the MVP development and will be ready to publish everything publicly.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_odd">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Growth <small>2023</small> <span><img src="assets/images/icon/roadmap_spiner.png" alt="" /></span></h3>
                                    <p>Mobile App</p>
                                    <br></br>
                                    <p>Collabs with top-notch producers and provides masterclass for newbies</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_even">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Investments and Accelerator Sprint <small>2023</small><span><img src="assets/images/icon/roadmap_spiner.png" alt="" /></span></h3>
                                    <p>Getting investments and being accelerate</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_odd">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Expanding The Ecosystem <small>2023</small> <span><img src="assets/images/icon/roadmap_spiner.png" alt="" /></span></h3>
                                    <p>Side-chain for creators</p>
                                    <br></br>
                                    <p>Building producer dApps at our sidechain</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="v2_roadmap_card_sect v2_roadmap_card_sect_even">
                                <div class="v2_roadmap_card_box"></div>
                                <div class="v2_roadmap_card">
                                    <h3>Domination <span><img src="assets/images/icon/roadmap_spiner.png" alt="" /></span></h3>
                                    <p>Dominate the rap music industry!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bithu_v2_roadmap_mass_gard">
                <img src="assets/images/nft/v2_roadmap_bg_shape.png" alt="" />
            </div>
        </div>
    </>
  )
}
